$primary-color: #ff9b05;
$xxl: 1679px;
$xl: 1439px;
$lg: 1199px;
$md: 991px;
$sm: 575px;
$xs: 359px;
// 360 576 768 992 1200 1366 1440 1680 1920
@mixin breakpoint($point) {
    @if $point == xxl {
        @media (max-width: $xxl) {
            @content;
        }
    }
    @if $point == xl {
        @media (max-width: $xl) {
            @content;
        }
    }
    @if $point == lg {
        @media (max-width: $lg) {
            @content;
        }
    }
    @if $point == sm {
        @media (max-width: $sm) {
            @content;
        }
    }

    @if $point == md {
        @media (max-width: $md) {
            @content;
        }
    }
    @if $point == xs {
        @media (max-width: $xs) {
            @content;
        }
    }
}
