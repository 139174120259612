.font-bold {
    font-weight: 700;
}
.font-medium {
    font-weight: 500;
}
.font-thin {
    font-weight: 400;
}
.txt-primary {
    color: #ff9b05;
}
.cursor-pointer {
    cursor: pointer;
}

.fontfa-primary {
    font-family: 'Inter', sans-serif;
}
.d-flex {
    display: flex;
    .label {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 12px;
    }
}
.flex-column {
    flex-direction: column;
}

.body-main-dashboard {
    margin-left: 256px;
    flex-basis: 100%;
    background-color: #121317;
    @media screen and (max-width: 1365px) {
        margin-left: 0px;
    }
}
.home-dashboard .menu-left-style {
    position: fixed;
    height: 100%;
}

.search-header-main {
    background-color: #1a1c20;
    position: fixed;
    width: calc(100vw - 256px);
    z-index: 100;
    @media screen and (max-width: 1365px) {
        width: 100vw;
    }
}
.align-center {
    align-items: center;
}
.w-100 {
    width: 100%;
}
.w-90 {
    width: 90%;
}
.mt-32 {
    margin-top: 32px;
}
.pb-16 {
    padding-bottom: 16px;
}
.flex {
    display: flex;
}

.justify-between {
    justify-content: space-between;
}
.justify-center {
    justify-content: center;
}
.justify-around {
    justify-content: space-around;
}
.flex-column {
    display: flex;
    flex-direction: column;
}
.text-center {
    text-align: center;
}
.flex-wrap {
    flex-wrap: wrap;
}
.btn {
    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }
}
.btn-no-border {
    border: none;
    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }
}

.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.d-none {
    display: none;
}
.txt-muted {
    color: rgba(255, 255, 255, 0.6);
}
