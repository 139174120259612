.home-dashboard {
    display: flex;
    min-height: 100vh;

    .menu-left-style {
        z-index: 10;
        flex-basis: 20%;
        min-width: 256px;
        max-width: 256px;
        background-color: #1a1c20;
        transition: 0.4s all;

        @media (max-width: 1365px) {
            position: fixed;
            height: 100%;
            margin-left: -300px;
        }
    }
}

.show-menu {
    @media (max-width: 1365px) {
        margin-left: 0 !important;
    }
}

.bg-after-menu {
    @media (max-width: 1365px) {
        content: ' ';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #00000070;
        z-index: 0;
    }
}

.icon-show-menu-mobile {
    //position: fixed;
    //top: 65px;
    //left: -15px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: none;
    align-items: center;
    justify-content: center;

    @media (max-width: 1365px) {
        display: flex;
    }

    span {
        font-size: 24px;
        color: #ffffff;
    }
}

.box-loading-search {
    text-align: center;
    padding-top: 250px;
    img {
        width: 200px;
        animation: move-icon-loading 2s infinite;
    }
}

.item-header {
    img.style-menu-logo {
        display: none;
    }
    @media (max-width: 1365px) {
        img.style-menu-logo {
            display: block;
        }
    }
}

@keyframes move-icon-loading {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-50px);
    }
    100% {
        transform: translateY(0px);
    }
}
