button {
    border: none;
    // test build
}

body {
    font-family: 'Inter', sans-serif;
    background: #131518;
    color: #fff;

    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}
body iframe {
    display: none !important;
}
body .secondary16 {
    font-size: 16px;

    font-weight: 400;
    line-height: 140%;
}
body .main16 {
    font-size: 16px;

    font-weight: 700;
    line-height: 140%;
}
body .secondary14 {
    font-size: 14px;

    font-weight: 400;
    line-height: 140%;
}
.btn-primary,
.btn-secondary {
    padding: 16px 24px;
    height: 49px;

    font-weight: bold;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    transition: 0.3s;
}
.btn-primary {
    background: var(--Primary, #17c168);
    color: #000;
    &:hover {
        background: #39fe97;
        color: #000;
    }
}
.btn-secondary {
    color: var(--Text-Button-Secondary, #17c168);
    border: 1px solid #17c168;
    background: transparent;
    &:hover {
        color: #39fe97;
        border-color: #39fe97;
    }
}

a:active {
    color: #17c168;
}

* {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: normal;
    font-variant: normal;
}
