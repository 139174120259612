//notifi

.ant-notification-notice {
    background: #3f4347;
    box-shadow: 0px 0px 16px rgba(17, 16, 57, 0.7);
    border-radius: 12px;
    color: #ffffff;
    font-size: 14px;
    line-height: 17px;
    padding: 24px 24px 24px 12px;
}
.ant-notification-notice-message {
    color: #ffffff;
}

.ant-notification-notice-message {
    font-weight: 700;
}
.ant-notification-close-x {
    color: #fff;
}
.noti--warning {
    border-bottom: 2px solid #ff9b05;
}
.noti--sucess {
    border-bottom: 2px solid #1a9800;
}

.noti--info {
    border-bottom: 2px solid #0085ff;
}

.noti--err {
    border-bottom: 2px solid #ca1118;
}
