//Select

.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown-placement-topLeft {
    background: #1d2125;
    /* Stroke */

    border: 1px solid #3a3f50;
    box-sizing: border-box;
    /* Drop Shadow */

    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    padding: 4px 24px;
}
.ant-select-item-option {
    color: #ffffff;
    transition: 0.3s;
    &:hover {
        background-color: transparent;
        @extend .txt-primary;
    }
}
.ant-select-item-option {
    padding: 12px 0;
    &:not(:first-child) {
        border-top: 0.5px solid #3a3f50;
    }
}
.ant-select-item-option-content {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Inter', sans-serif;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: transparent;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    @extend .txt-primary;
    background: transparent;
}

.ant-skeleton {
    background: #131518;
}

//modal

.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.8);
}

.ant-spin-dot-item {
    background-color: #17c168;
}

.defusion-modal {
    .ant-modal-content {
        background: #1d2125;
        /* Drop Shadow */

        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.8);
        border-radius: 24px;
    }
}
