.content-header-page {
    height: 68px;
    background-color: #131418;
    .row-header-1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding-left: 24px;
        padding-right: 24px;

        @media (max-width: 700px) {
            padding-left: 16px;
            padding-right: 16px;
        }

        .left-header {
            display: flex;
            align-items: center;

            .icon-back {
                width: 32px;
                height: 32px;
                border: 1px solid #ffffff20;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin-right: 21px;
                cursor: pointer;

                @media (max-width: 700px) {
                    margin-right: 5px;
                }

                .style-image-back {
                    width: 15px;
                    height: 12px;
                }
            }
            .icon-logo {
                margin-right: 14px;

                @media (max-width: 700px) {
                    transform: scale(0.6);
                    margin-right: 4px;
                }

                .style-image-logo {
                    width: 37px;
                    height: 43px;
                }
            }
            .title-back{
                font-size: 32px;
                font-family: Ariston_Comic_Font, serif;
                color: #fff;

                @media (max-width: 700px) {
                    font-size: 24px;
                }
            }
        }
        .right-header {
            .btn-connect-wallet-bat {
                width: 154px;
                height: 48px;
                background-image: url("../../../assets/images/battleFields/background-blue-btn.svg");
                background-size: 100% 100%;
                background-color: transparent;
                outline: 0;
                border: 0;
                cursor: pointer;
                transition: 0.5s all;

                @media (max-width: 700px) {
                    width: auto;
                    padding-left: 10px;
                    padding-right: 10px;
                }

                &:hover {
                    filter: brightness(80%);
                }
            }
        }
    }
}

.text-shadow {
    position: relative;
    min-width: 100px;

    &::after {
        content: attr(data-title);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        height: 100%;
        color: #ffffff;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 700px) {
            font-size: 12px;
        }
    }
    &::before {
        content: attr(data-title);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        height: 100%;
        color: #ffffff;
        text-shadow: 0px 2px 0px #000000, 1px 2px 0px #000000, -1px 2px 0px #000000;
        font-weight: bold;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: black;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 700px) {
            font-size: 12px;
        }
    }
}
