.search-header-main {
    .container-search-header {
        .box-search {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 60px;
            padding: 0 24px;

            @media (max-width: 767px) {
                //flex-wrap: wrap;
                padding: 20px 24px;
                // height: auto;
            }
            @media (max-width: 575px) {
                padding: 10px 16px;
                justify-content: space-between;
            }

            .item-header {
                @media (max-width: 1199px) {
                    //flex-basis: 100%;

                    // &:last-of-type {
                    //     width: calc(100% - 25px);
                    //     display: flex;
                    //     justify-content: center;
                    // }
                    .connect-wallet button {
                        background: #2d3236;
                        color: #ff9b05;
                    }
                }
                @media (max-width: 359px) {
                    .connect-wallet button {
                        width: 135px;
                    }
                }

                .row-input-search {
                    border: 1px solid #d5d6d750;
                    border-radius: 8px;
                    padding: 5px 5px 5px 20px;
                    width: 450px;
                    display: flex;
                    align-items: center;

                    @media (max-width: 767px) {
                        display: none;
                    }

                    // @media (max-width: 767px) {
                    //     width: 100%;
                    //     margin: auto;
                    // }

                    span {
                        color: #ffffff;
                    }
                    .style-input-search {
                        background-color: transparent;
                        border: 0;
                        outline: 0;
                        padding-left: 15px;
                        color: #d5d6d7;
                        width: 93%;
                    }
                }
            }
        }
    }
}
.connect-wallet {
    button {
        width: 152px;
        height: 40px;
        background: #ff9b05;
        border-radius: 8px;
        font-size: 14px;
        line-height: 17px;
        color: #000;
        font-weight: bold;
        cursor: pointer;
    }
}

.box-search {
    .icon-show-menu-mobile {
        margin-right: 10px;
    }

    .item-header {
        align-items: center;
    }
}
