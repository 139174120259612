@import '../../../assets/scss/variables';

.small-txt {
    @include breakpoint(xs) {
        line-height: 14px !important;
        font-size: 10px !important;
    }
}

.market .cards {
    min-height: 900px;

    padding: 0px 0 130px 0;
    background-color: #131518;

    @include breakpoint(lg) {
        padding: 0 0 30px 0;
    }
    @include breakpoint(sm) {
        padding: 6px 16px 120px;
    }
    &-container {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;

        border: 1px solid #3a3f50;

        border-radius: 8px;

        // @include breakpoint(lg) {
        //     width: 100%;
        //     padding: 0 16px;
        //     justify-content: flex-start;
        // }
    }
}

.card-item {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-basis: 25%;
    max-width: 327px;
    height: 394px;
    position: relative;

    background: #1d2125;
    border: 1px solid #3a3f50;
    border-left: none;

    &:nth-child(4n) {
        border-right: none;
    }

    &:last-child {
        border-right: 1px solid #3a3f50;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
        border-top: none;
    }
    border-bottom: none;

    box-sizing: border-box;

    transition: 0.3s;
    color: #fff;
    @include breakpoint(xl) {
        height: auto;
    }
    @include breakpoint(md) {
        flex-basis: calc(100% / 3);
        &:nth-child(3n) {
            //
            border-right: none;
        }
        &:nth-child(4n) {
            border-right: 1px solid #3a3f50;
        }
        &:nth-child(4) {
            border-top: 1px solid #3a3f50;
        }
    }

    @include breakpoint(sm) {
        // min-width: 158px;
        flex-basis: 50%;

        &:nth-child(2n) {
            border-right: none;
        }
        &:nth-child(3n) {
            border-right: 1px solid #3a3f50;
        }
        &:nth-child(3) {
            border-top: 1px solid #3a3f50;
        }
    }

    &:hover {
        cursor: pointer;
        .card-item__img img {
            transform: scale(1.2);
        }

        .card-item__content {
            border: 1px solid $primary-color;
            box-shadow: 0px 0px 16px rgb(255 138 0 / 60%);
        }
    }

    &__level {
        border: 1px solid #3a3f50;
        border-top: none;
        margin-left: 16px;
        display: flex;
        padding: 0 21px;
        height: 25px;
        border-radius: 0px 0px 8px 8px;
        display: inline-flex;
        align-items: center;
        width: fit-content;

        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        // background: -webkit-linear-gradient(#fff505, #ff9b05);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        color: #eaad50;
        svg {
            margin-right: 4px;
        }
    }

    &__img {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex: 1 1;
        padding: 15px 40px;
        max-width: 100%;

        @include breakpoint(sm) {
            padding: 16px 22px;
            height: 152px;
        }
        img {
            transition: 0.3s;
            max-width: 230px;
            max-height: 180px;

            @include breakpoint(sm) {
                max-width: 120px;
            }

            @include breakpoint(xs) {
                max-width: 90px;
            }
        }

        img.egg {
            max-height: 230px;
        }
    }

    &__content {
        transition: 0.2s;
        // height: 92px;
        width: calc(100% - 32px);
        margin: 0 auto 16px;
        padding: 16px;
        cursor: initial;
        background: #2b2f3c;
        border: 1px solid #3a3f50;
        box-sizing: border-box;
        border-radius: 8px;

        @include breakpoint(xl) {
            height: auto;
            padding: 8px;
        }

        .card-item-name {
            font-size: 14px;
            line-height: 18px;
            overflow: hidden;
            max-width: 70%;
            word-break: break-word;
            @include breakpoint(xl) {
                font-size: 12px;
            }
            @extend .small-txt;
        }
        .card-item-props {
            .card-item-rarity {
                margin-left: 10.5px;
                @include breakpoint(xl) {
                    margin-left: 4px;
                }
                @include breakpoint(xs) {
                    margin-left: 0px;
                }
            }
            .card-item-type,
            .card-item-rarity {
                cursor: pointer;
                img {
                    width: 32px;
                    @include breakpoint(xl) {
                        max-width: 15px;
                    }
                }
            }
        }
        .line1 {
            margin-bottom: 15px;
            @include breakpoint(xl) {
                margin-bottom: 4px;
            }
        }

        .line1,
        .line2 {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        .line2 {
            font-size: 14px;
            line-height: 24px;

            @include breakpoint(xl) {
                flex-direction: column;
                align-items: flex-start;
                line-height: 20px;
                font-size: 12px;
            }
            @extend .small-txt;

            .price-value {
                background: -webkit-linear-gradient(#ace1ff, #53c0fe);
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .usd-price {
            color: #fff;
            opacity: 0.7;
            font-weight: normal;
            font-size: 12px;
            @media (max-width: 359px) {
                font-size: 10px;
            }
        }
    }
}

.supper-tiny-item {
    position: relative;
    height: auto;
    background-color: transparent;

    @media (max-width: 575px) {
        flex-basis: 100%;
        max-width: unset;
    }
    .card-item__img {
        align-items: flex-start;
        padding: 0;
        position: relative;
        @media (max-width: 575px) {
            height: unset;
        }

        .lazyload-wrapper {
            width: 100%;
            height: 100%;
            overflow: hidden;
            max-height: 325px;
            @media (max-width: 575px) {
                max-height: unset;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: top center;
                max-width: unset;
                max-height: unset;
            }
        }

        .level-tiny {
            position: absolute;
            top: 0;
            left: 16px;
            background: #1d2125;
            border-radius: 0px 0px 8px 8px;
            width: 113px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                transform: scale(1) !important;
                width: 16px;
                height: 16px;
            }

            .text-level {
                background: -webkit-linear-gradient(#fff505, #ff9b05);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-left: 7px;
                font-size: 13px;
                font-weight: 500;
            }
        }
    }

    .card-item__content {
        width: 100%;
        margin: auto;
        position: relative;
        border-radius: 0;

        &::after {
            position: absolute;
            top: -10px;
            left: 0;
            background-color: #2b2f3c;
            content: ' ';
            width: 100%;
            height: 10px;
            border-radius: 20px 20px 0 0;
        }

        .line1 {
            align-items: center;
            .item-tiny-ticket {
                color: #ace1ff;
                font-size: 14px;
                img {
                    margin-right: 8px;
                }
            }
        }

        .row-tiny-btn {
            display: flex;
            justify-content: space-between;
            .left-tiny-btn {
                flex-basis: calc(50% - 10px);
                .btn-defusion {
                    border: 1px solid #1f9fff;
                    background: #1f9fff;
                    color: #000000;
                    width: 100%;
                    height: 100%;
                    height: 40px;
                    color: #fff;
                    border-radius: 8px;
                    font-size: 14px;
                    font-weight: bold;
                    transition: 0.5s all;
                    cursor: pointer;

                    &:hover {
                        background-color: transparent;
                        color: #1f9fff;
                    }
                }
            }
            .right-tiny-btn {
                flex-basis: calc(50% - 10px);

                .btn-battle-fields {
                    background-color: #ff9b05;
                    color: #000000;
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    font-size: 14px;
                    font-weight: bold;
                    transition: 0.5s all;
                    cursor: pointer;
                    border: 1px solid #ff9b0500;

                    &:hover {
                        border: 1px solid #ff9b05;
                        background: transparent;
                        color: #ff9b05;
                    }
                }
            }
        }
    }
}

.content-price {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    .price-title {
        font-size: 12px;
        line-height: 16px;
        opacity: 0.7;
        color: #fff;
        text-align: right;

        @include breakpoint(xl) {
            font-size: 12px;
            line-height: 18px;
        }
    }
    .price-value {
        margin-top: 8px;
        font-size: 16px;
        line-height: 24px;
        color: $primary-color;
        @include breakpoint(xl) {
            font-size: 14px;
            line-height: 20px;
        }
        // @include breakpoint(sm) {
        //     margin-top: 0;
        //     font-size: 14px;
        //     line-height: 20px;
        // }
        img {
            margin-right: 4px;
            @include breakpoint(sm) {
                width: 20px;
            }
        }
    }
}

.cards-pagination {
    padding-top: 36px;
}
