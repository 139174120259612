.footer-page {
    background-color: #1b1e22;

    .container-footer {
        max-width: 1320px;
        margin: auto;
        padding: 120px 0;

        @media (max-width: 1320px) {
            padding-left: 24px;
            padding-right: 24px;
            max-width: 100%;
        }
        @media (max-width: 700px) {
            padding: 60px 16px;
            max-width: 100%;
        }

        // @media (max-width: 575px) {
        //     width: 100%;
        //     padding: 30px 16px 70px;
        // }
        .top-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 700px) {
                flex-direction: column;
                gap: 12px;
            }
        }

        .list-row-item {
            display: flex;
            gap: 24px;

            @media (max-width: 700px) {
            }

            a {
                text-align: center;
                color: #ffffff;
                padding: 16px;
                &:last-child {
                    margin-right: 0;
                }

                .style-icon-link {
                    width: 24px;
                    height: 24px;
                    transition: 0.3s;
                    &:hover {
                        transform: scale(1.1);
                    }
                }

                .title-social {
                    font-size: 16px;
                    line-height: 16px;

                    @media (max-width: 700px) {
                        font-size: 14px;
                        line-height: 14px;
                    }
                }
            }
        }
        .logo-footer {
            display: flex;
            justify-content: center;
            align-items: center;

            .title-logo-footer {
                font-size: 18px;
                color: #ffffff;
                margin-left: 10px;
                font-weight: bold;
            }
        }
        .email-contact {
            padding-top: 24px;
            border-top: 1px solid rgba(255, 255, 255, 0.6);
            margin-top: 24px;
            font-size: 16px;

            font-weight: 400;
            line-height: 140%;
            color: #fff;
            a {
                color: #ffffff;
                font-weight: 700;
            }
        }
        .description-contact {
            font-size: 14px;
            line-height: normal;
            color: rgba(255, 255, 255, 0.6);
            margin-top: 8px;
        }
    }
}
