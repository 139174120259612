@import '../../../assets//scss//variables.scss';

.market .filter-container {
    padding-top: 93px;
    color: #fff;
    position: relative;
    display: flex;

    align-items: center;

    @include breakpoint(xl) {
        flex-direction: column;
        width: 100%;
        padding-top: 79px;
    }
    @include breakpoint(sm) {
        padding-top: 79px;
    }
}

.filter-wrapper {
    @include breakpoint(xl) {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;

        .market-price {
            margin-bottom: 18px;
        }
    }
    @include breakpoint(md) {
        margin-bottom: 0;
    }
    flex: 1;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    margin-bottom: 24px;
    @include breakpoint(md) {
        width: 100%;
    }
    .dropdown-group {
        @include breakpoint(xl) {
            width: 100%;
        }
        flex-wrap: wrap;
        @include breakpoint(md) {
            .filter-dropdown {
                width: calc(50% - 6px);
                &:nth-child(2n) {
                    margin-right: 0;
                }

                margin-bottom: 18px;
                .ant-select {
                    width: 100% !important;
                }
            }
        }

        @include breakpoint(xs) {
            .ant-select-selector {
                font-size: 10px !important;
            }
        }
    }
}

.btn-filter {
    width: 143px;
    border-radius: 8px;
    height: 42px;
    display: flex;

    padding: 0 24px;
    align-items: center;
    background-color: transparent;
    justify-content: space-between;
    font-size: 1.4em;
    line-height: 20px;

    cursor: pointer;
    font-weight: 500;
    border: 1px solid #3a3f50;

    margin-right: 26px;
    &:hover {
        border: 1px solid #40a9ff;
    }
}

.filter-slider {
    display: flex;
    align-items: center;
    @include breakpoint(lg) {
        flex-direction: column;
        align-items: flex-start;
    }
    span.slider-name {
        margin-right: 24px;
        padding-top: 24px;
    }
    .ant-slider-dot {
        top: 0;
    }
    > div {
        width: 302px;
        @include breakpoint(sm) {
            width: 270px;
        }
    }
}

.filter-tabs {
    margin-right: 24px;
    opacity: 0.7;
    display: flex;
    font-size: 1.4em;
    line-height: 18px;

    width: fit-content;
    height: 42px;
    border: 1px solid #3a3f50;
    border-radius: 8px;

    @include breakpoint(md) {
        margin: 12px 0;
    }

    .tab-item {
        display: flex;
        align-items: center;
        padding-left: 24px;
        padding-right: 24px;

        transition: 0.3s;
        height: 100%;
        cursor: pointer;
        border: 1px solid transparent;
        font-weight: 400;

        font-size: 14px;
        line-height: 18px;

        transition: 0.3s;
        @include breakpoint(xs) {
            padding: 0 12px;
        }

        &--active {
            background-color: #ff9b05;
            border-radius: 7px;
            border: none;

            font-weight: 700;
            color: #000;
            opacity: 1;
        }

        &:hover:not(.tab-item--active) {
            color: #ff9b05;
            font-weight: 400;
        }
    }
}

.filter-attr {
    display: flex;

    @include breakpoint(lg) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.market-price {
    font-size: 20px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(lg) {
        font-size: 16px;
        line-height: 24px;
    }
    // @include breakpoint(sm) {
    //     width: calc(100% - 235px);
    //     display: block;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     white-space: nowrap;
    // }
    @include breakpoint(xs) {
        font-size: 14px;
        line-height: 18px;
    }
}

.spaces {
    margin-bottom: 12px;
}

// .filter-popover {
//     .ant-popover-content {
//         @include breakpoint(xs) {
//             margin-left: unset;
//         }
//     }
// }

.filter-dropdown {
    &:not(:last-child) {
        margin-right: 12px;
    }
    .label {
        @include breakpoint(md) {
            margin-bottom: 8px;
        }
        opacity: 0.7;
    }
}

.filter-types-wrapper {
    display: flex;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    display: flex;
    .filter-type-item {
        &:first-child {
            margin-right: 10px;
        }
        &:last-child {
            margin-left: 10px;
        }
        cursor: pointer;
        width: 120px;
        height: 120px;
        @include breakpoint(xl) {
            width: 102px;
            height: 102px;
        }
        position: relative;

        background: #131418;
        border: 2px solid #3a3f50;
        box-sizing: border-box;
        border-radius: 16px;
        display: flex;

        justify-content: center;
        align-items: center;
        font-size: 16px;
        line-height: 19px;
        flex-direction: column;
        span {
            opacity: 0.7;
        }

        img {
            margin-bottom: 15px;
        }
        .arrow-down {
            width: 0px;
            height: 0px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 13px solid #ff9b05;
            position: absolute;
            bottom: -13px;
            display: none;
        }

        &--active {
            border-color: #ff9b05;
            box-shadow: 0px 0px 16px rgba(255, 138, 0, 0.6);
            color: #ff9b05;

            span {
                opacity: 1;
            }

            .arrow-down {
                display: block;
            }
        }
    }

    .background-super-tiny {
        background: radial-gradient(50% 50% at 50% 50%, #0F7119 0%, rgba(19, 20, 24, 0) 100%);
        background-size: 100% 100%;
    }
}
