.logout-modal {
    z-index: 10000;

    .ant-modal {
        width: 516px !important;

        .ant-modal-content {
            border-radius: 24px;
            background: #1d2125;

            .ant-modal-close {
                top: 10px;
                right: 15px;

                .ant-modal-close-x {
                    font-size: 18px;

                    svg {
                        fill: rgba(255, 255, 255, 0.7);
                    }
                }
            }

            .ant-modal-header {
                border-radius: 24px 24px 0 0;
                padding: 24px 32px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                background: inherit;

                .ant-modal-title {
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: bold;
                    color: #fff;
                }
            }

            .ant-modal-body {
                padding: 24px 32px;
                line-height: 1.5;

                .content {
                    background: #131518;
                    border-radius: 16px;
                    padding: 24px;

                    .title {
                        color: #fff;
                        margin-bottom: 16px;
                        opacity: 0.7;
                        line-height: 17px;
                    }

                    .address {
                        color: #fff;
                        line-height: 17px;
                        margin-bottom: 16px;
                    }

                    .actions {
                        display: flex;
                        align-items: center;
                        color: #ff9b05;

                        @media screen and (max-width: 576px) {
                            flex-direction: column;
                            align-items: flex-start;
                            .copy {
                                margin: 8px 0;
                            }
                        }

                        img {
                            margin-right: 8px;
                        }

                        a {
                            color: #ff9b05;
                        }

                        .copy {
                            margin-right: 36px;
                            cursor: pointer;

                            &:active {
                                filter: blur(1px);
                            }
                        }
                    }
                }
            }

            .ant-modal-footer {
                padding: 0 32px 24px;
                border: none;
                border-radius: 0 0 24px 24px;
                display: flex;
                justify-content: flex-end;

                button {
                    width: 173px;
                    height: 40px;
                    border: 1px solid #ff9b05;
                    border-radius: 8px;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 17px;
                    color: #ff9b05;
                    background: inherit;
                    cursor: pointer;
                }
            }
        }
    }
}
