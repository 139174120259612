.box-menu-left-dash {
    .container-box-menu {
        .header-menu-logo {
            height: 60px;
            display: flex;
            align-items: center;
            padding-left: 20px;
        }
        .list-menu-left {
            margin-top: 20px;
            .menu-list {
                padding-left: 0;
                .item-menu {
                    list-style: none;
                    padding: 0 16px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    color: #ffffff;
                    position: relative;
                }
                .item-collect-wallet {
                    margin-top: 16px;
                }

                .active-menu {
                    position: relative;
                    color: #ff9b05;
                    background: rgba(255, 155, 5, 0.1);

                    &:after {
                        content: ' ';
                        position: absolute;
                        top: 0;
                        right: 0;
                        background-color: #ff9b05;
                        width: 4px;
                        height: 100%;
                    }

                    .href-menu {
                        color: #ff9b05;
                    }
                }
            }
            .connect-wallet {
                display: none;
                @media screen and (max-width: 1199px) {
                    display: block;
                }
            }
        }
    }
}

.style-menu-logo {
    max-width: 105px;

    @media screen and (max-width: 359px) {
        max-width: 90px;
    }
}

.item-menu__logo {
    margin-right: 13px;
    img {
        width: 24px;
        object-fit: contain;
        height: 24px;
    }
}
